import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineNotification mdxType="InlineNotification">
      <p>{`Legion Crossplatform UI package is currently available as an NPM package. This package is intended to be used in React Native or React projects.`}</p>
    </InlineNotification>
    <br />
    <h2>{`Prequisites`}</h2>
    <p>{`Already have at least these below:`}</p>
    <ul>
      <li parentName="ul">{`Nodejs v16 or above, if you are using multiple versions of node, we recommend using nvm to manage its version`}</li>
      <li parentName="ul">{`Node Package Manager v8 or above`}</li>
      <li parentName="ul">{`Yarn v1.22.19 or above`}</li>
    </ul>
    <div className="divi" />
    <h2>{`Installation`}</h2>
    <h3><strong parentName="h3">{`Proxy and Authentication`}</strong></h3>
    <p>{`With either one of your package manager.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# npm
npm i @legion-crossplatform/ui

# yarn
yarn add @legion-crossplatform/ui
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      